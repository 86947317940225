import * as serviceWorker from "./tools/serviceWorker";
import ready from "./tools/dom/ready";
import IndexHeader from "../components/index-header/index-header.js";
import IndexSwiper from "../components/index-swiper/index-swiper.js";
import SideMenu from "../components/side-menu/side-menu.js";
import BrandSwiper from "../components/brand-swiper/brand-swiper.js";
import AOS from "aos";
import createParallax from "./plugins/basicScroll";
import BrandTabs from "../components/brand-tabs/brand-tabs";
import HeaderLinks from "../components/header-links/header-links";
import BrandMenu from "../components/brand-menu/brand-menu";
import BrandDropdown from "../components/brand-dropdown/brand-dropdown";
import BrandForm from "../components/brand-form/brand-form";
import BrandStructure from "../components/brand-structure/brand-structure";
import BrandCollapse from "../components/brand-collapse/brand-collapse";
import BrandMap from "../components/brand-map/brand-map";
import BrandModal from "../components/brand-modal/brand-modal";

AOS.init();

// require('./plugins/jquery.min');
// require('./plugins/modernizr-custom');
// require('./plugins/stats.min');
// require('./plugins/three.min');
// require('./plugins/rivets.bundled.min');
// require('./plugins/app');

// DOMContentLoaded
window.global = {};
window.global.indexSwiper = [];
ready(function () {
  global.modal = [];
  if (document.querySelector('.js-brand-modal')) {
    global.modal = new BrandModal();
  }
  new SideMenu();
  new HeaderLinks();
  new IndexHeader();
  global.indexSwiper = [];
  document.querySelectorAll('.js-index-swiper').forEach((elem) => {
    window.global.indexSwiper[elem.getAttribute('data-index-swiper')] = new IndexSwiper(elem);
  });
  document.querySelectorAll('[data-swiper]').forEach((elem) => {
    new BrandSwiper(elem);
  });
  document.querySelectorAll('.js-brand-collapse').forEach((elem) => {
    new BrandCollapse(elem);
  });
  document.querySelectorAll('.js-brand-tabs').forEach(() => {
    new BrandTabs();
  });
  document.querySelectorAll('[data-map]').forEach((elem) => {
    new BrandMap(elem.getAttribute('id'));
  });
  global.dropdown = [];
  document.querySelectorAll('[data-dropdown]').forEach((elem) => {
    global.dropdown[elem.getAttribute('data-dropdown')] = new BrandDropdown(elem);
  });
  global.form = [];
  document.querySelectorAll('.js-brand-form').forEach((elem) => {
    global.form[elem.getAttribute('data-form')] = new BrandForm(elem);
  });
  if (document.querySelectorAll('.js-brand-menu').length) {
    new BrandMenu();
  }
  if (document.querySelector('.js-brand-structure')) {
    new BrandStructure();
  }
  createParallax({});
});

// If you want your app to work offline and load faster, you can change
// `unregister()` to `register()` below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

export {}
