class BrandTabs {
  constructor() {
    this.anchors = Array.from(document.querySelectorAll('.js-tabs-anchor'));
    this.tabs = Array.from(document.querySelectorAll('.js-tabs-tab'));
    this.activeTabId = null;
    this.init();
    this.events();
  }

  init() {
    this.anchors.forEach((elem) => {
      if (elem.classList.contains('state_active')) {
        this.activeTabId = elem.getAttribute('href').slice(1);
        const $tab = document.querySelector(`#${this.activeTabId}`);
        $tab.classList.add('state_active');
      }
    });
  }

  events() {
    this.anchors.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        e.preventDefault();
        // удаляем класс активности с якоря и таба
        let $activeTab = this.anchors.filter(item => item.classList.contains('state_active'));
        if (this.activeTabId !== e.target.getAttribute('href').slice(1)) {
          $activeTab[0].classList.remove('state_active');
          document.querySelector(`#${this.activeTabId}`).classList.remove('state_active');

          //добавляем класс активности к новому набору
          e.target.classList.add('state_active');
          this.activeTabId = e.target.getAttribute('href').slice(1);
          $activeTab = document.querySelector(`#${this.activeTabId}`);
          $activeTab.classList.add('state_active');
          const $parent = $activeTab.parentElement;
          $parent.style.transform = `translateX(calc(${-this.tabs.indexOf($activeTab)} * 100%))`;
          console.log('PARENT', this.tabs.indexOf($activeTab));
        }
      })
    })
  }
}

export default BrandTabs;
