class SideMenu {
  constructor() {
    this.menu = document.querySelector('.js-side-menu');
    this.controls = document.querySelector('.js-side-menu-controls');
    this.links = document.querySelectorAll('.js-side-menu-group');
    this.init();
    this.events();
  }

  init() {
  }

  events() {
    this.controls.addEventListener('click', (e) => {
      if (this.menu.classList.contains('state_explored')) {
        this.menu.classList.remove('state_init');
        this.menu.classList.remove('state_explored');
        document.body.classList.remove('state_fixed');
      } else {
        this.menu.classList.add('state_explored');
        // this.menu.classList.remove('state_init');
        document.body.classList.add('state_fixed');
      }
    });

    this.links.forEach((el, index) => {
      el.addEventListener('mouseover', (e) => {
        e.currentTarget.classList.add('state_hover');
        e.fromElement.classList.remove('state_active');
        e.target.classList.add('state_active');
      });

      el.addEventListener('mouseout', (e) => {
        e.fromElement.classList.remove('state_active');
        e.currentTarget.classList.remove('state_hover');
      });
    });
  }
}

export default SideMenu;
