class BrandMenu {
  constructor() {
    this.$wrapper = document.querySelectorAll('.js-brand-menu-group')[0];
    this.$items = this.$wrapper.children;
    this.$active = this.$wrapper.querySelector('.state_active');
    this.init();
    this.events();
  }

  init() {
    addEventListener('load', () => {
      this.setMenuPosition();
    })
  }

  events() {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        this.setMenuPosition();
      }
    })
  }

  setMenuPosition() {
    if (window.innerWidth <= this.$wrapper.clientWidth + 60) {
      let scrollWidth = 0;
      const lastWidth = this.$items[this.$items.length - 1].clientWidth;
      for (let i = 0; i < this.$items.length - 1; i += 1) {
        if (!this.$items[i].classList.contains('state_active')) {
          scrollWidth += this.$items[i].clientWidth;
        } else {
          break;
        }
      }
      this.$wrapper.style.marginRight = `${window.innerWidth - (window.innerWidth < 576 ? lastWidth + 30 : lastWidth + (window.innerWidth - 516) / 2)}px`;
      this.$wrapper.parentElement.scrollLeft = window.innerWidth < 576 ? scrollWidth : scrollWidth - (window.innerWidth - 576) / 2;
    } else {
      if (window.innerWidth > 767) {
        this.$wrapper.style.marginRight = 'auto';
      } else {
        this.$wrapper.style.marginRight = '30px';
      }
    }
  }
}

export default BrandMenu;
