class IndexHeader {
  constructor() {
    this.init();
  }

  init() {

  }
}

export default IndexHeader;
