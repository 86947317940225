import Swiper from 'swiper';

class BrandSwiper {
  constructor(elem) {
    this.swiper = null;
    this.id = elem.getAttribute('data-swiper');
    this.init();
  }

  init() {
    console.log('BRAND_SWIPER', this.swiper);
    if (window.innerWidth < 768) {
      if (this.swiper === null) {
        this.swiper = new Swiper('[data-swiper="' + this.id + '"]', {
          slidesPerView: 'auto',
          centeredSlides: true,
          autoHeight: false,
          pagination: {
            el: '[data-pagination="' + this.id + '"]',
            clickable: true,
            renderBullet: function (index, className) {
              return '<div class="brand-swiper__bullet ' + className + '"></div>';
            },
          }
        });
      }
    } else {
      if (this.swiper !== null) {
        this.swiper.destroy(true, true);
      }
    }
  }
}

export default BrandSwiper;
