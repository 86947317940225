class BrandMap {
  constructor(id) {
    this.$map = document.querySelector(`#${id}`);
    this.yandexMap = null;
    this.id = id;

    this.init();
  }

  init() {
    ymaps.ready(() => {
      this.yandexMap = new ymaps.Map(`${this.id}`, {
        center: JSON.parse(this.$map.getAttribute('data-marker')),
        zoom: this.$map.getAttribute('data-zoom'),
        controls: []
      });
      this.yandexMap.controls.add('zoomControl');
      const MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
        '<div style="color: #002E79;">$[properties.iconContent]</div>'
      );
      const myPlacemark = new ymaps.Placemark(JSON.parse(this.$map.getAttribute('data-marker')), {},
        {
        iconLayout: 'default#imageWithContent',
        iconImageHref: 'assets/images/marker_symbol.svg',
        iconImageSize: [33, 73],
        iconImageOffset: [-16, -73],
        iconContentLayout: MyIconContentLayout
      });

      this.yandexMap.geoObjects
        .add(myPlacemark)
    });
  }
}

export default BrandMap;
