class BrandCollapse {
  constructor(elem) {
    this.$collapse = elem;
    this.$header = this.$collapse.querySelector('.js-collapse-header');
    this.$content = this.$collapse.querySelector('.js-collapse-content');
    this.$controls = this.$collapse.querySelector('.js-collapse-controls');
    this.explored = false;
    this.initialHeight = null;

    this.init();
    this.events();
  }

  init() {
    const styles = window.getComputedStyle(this.$content, null);
    this.initialHeight = styles['max-height'];
  }

  events() {
    this.$controls.addEventListener('click', () => {
      const styles = window.getComputedStyle(this.$content.firstElementChild, null);
      if (this.explored) {
        this.$collapse.classList.remove('state_explored');
        this.$content.style.maxHeight = this.initialHeight;
        this.explored = false;
      } else {
        this.$collapse.classList.add('state_explored');
        this.$content.style.maxHeight = styles.height;
        this.explored = true;
      }
    })
  }
}

export default BrandCollapse;
