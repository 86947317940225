import Inputmask from "inputmask";
import Validator from 'validatorjs';

class BrandForm {
  constructor(elem) {
    this.$form = elem;
    this.$fields = this.$form.querySelectorAll('.js-input-field');
    this.$submit = this.$form.querySelector('.js-submit-button');
    this.$fieldset = this.$form.querySelector('.js-form-fieldset');
    this.$success = this.$form.querySelector('.js-form-response.state_success');
    this.$error = this.$form.querySelector('.js-form-response.state_error');
    this.rules = null;
    this.applyMask();
    this.applyValidation();
    this.events();
  }

  applyValidation() {
    this.rules = {
      mail: 'email',
      phone: 'size:10'
    }
  }

  applyMask() {
    Inputmask({
      mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
      greedy: false,
      showMaskOnHover: false,
      onBeforePaste: function (pastedValue, opts) {
        pastedValue = pastedValue.toLowerCase();
        return pastedValue.replace("mailto:", "");
      },
      definitions: {
        '*': {
          validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
          casing: "lower"
        }
      }
    }).mask('[data-mask="mail"]');

    Inputmask({
      mask: '+7(999) 999-99-99',
      placeholder: '+7(___) ___-__-__',
      autoUnmask: true,
      showMaskOnHover: false,
      greedy: false
    }).mask('[data-mask="phone"]');
  }

  events() {
    this.$fields.forEach((elem) => {
      elem.addEventListener('input', (e) => {
        this.checkFieldValidation(e.currentTarget);
      })
    });

    this.$form.addEventListener('submit', (e) => {
      e.preventDefault();
      const xhr = new XMLHttpRequest();
      xhr.open('POST', e.currentTarget.getAttribute('data-action'), true);
      xhr.send(new FormData(this.$form));

      xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) return;
        this.$fieldset.classList.add('state_hidden');
        this.$submit.classList.add('state_hidden');
        if (xhr.status !== 200) {
          console.warn(xhr.status + ': ' + xhr.statusText);
          this.$error.classList.remove('state_hidden');
        } else {
          this.$success.classList.remove('state_hidden');
        }
      }
    })
  }

  checkFieldValidation(elem) {
    if (elem.getAttribute('data-validation') !== 'novalid') {
      const data = {};
      data[elem.getAttribute('data-validation')] = elem.value;
      let validation = new Validator(data, this.rules);
      if (validation.passes()) {
        elem.classList.add('state_filled');
      }
      if (validation.fails()) {
        elem.classList.remove('state_filled');
      }
    } else {
      if (elem.value.length) {
        elem.classList.add('state_filled');
      }
    }
    if (!elem.value.length) {
      elem.classList.remove('state_filled');
    }
    this.checkFormValidation();
  }

  checkFormValidation() {
    let valid = true;
    this.$fields.forEach((elem) => {
      if (elem.getAttribute('required')) {
        if (!elem.classList.contains('state_filled')) {
          valid = false;
        }
      }
    });
    if (valid) {
      this.$submit.removeAttribute('disabled')
    } else {
      this.$submit.setAttribute('disabled', 'disabled');
    }
  }
}

export default BrandForm;
