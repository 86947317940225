class BrandDropdown {
  constructor(elem) {
    this.$dropdown = elem;
    this.$field = this.$dropdown.querySelector('.js-input-field');
    this.$hidden = this.$dropdown.querySelector('.js-input-hidden');
    this.$items = this.$dropdown.querySelectorAll('.js-dropdown-item');
    this.events();
  }

  events() {
    this.$field.addEventListener('focus', () => {
      this.$dropdown.classList.add('state_explored');
    });

    this.$field.addEventListener('blur', () => {
      setTimeout(() => {
        if (!this.$dropdown.querySelector('.js-dropdown-item.state_active')) {
          this.$field.value = "";
          this.clearActiveItem();
        }
        this.$dropdown.classList.remove('state_explored');
      }, 100)
    });

    this.$field.addEventListener('input', (e) => {
      const value = e.currentTarget.value;
      this.clearActiveItem();
      if (value.length) {
        this.$items.forEach((elem) => {
          if (elem.getAttribute('data-title').toLowerCase() === value.toLowerCase()) {
            elem.classList.add('state_active');
            this.$field.value = elem.getAttribute('data-title');
            this.$field.classList.add('state_filled');
            this.$hidden.value = elem.getAttribute('data-value');
          }
        });
      }
      global.form['feedback'].checkFieldValidation(e.currentTarget);
    });

    this.$items.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        this.clearActiveItem();
        e.currentTarget.classList.add('state_active');
        this.$field.value = e.currentTarget.getAttribute('data-title');
        this.$field.classList.add('state_filled');
        this.$hidden.value = e.currentTarget.getAttribute('data-value');
        global.form['feedback'].checkFieldValidation(this.$field);
      })
    });
  }

  clearActiveItem() {
    this.$items.forEach((elem) => {
      elem.classList.remove('state_active');
    });
    this.$hidden.value = "";
    this.$field.classList.remove('state_filled');
  }
}

export default BrandDropdown;
