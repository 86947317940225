class BrandModal {
  constructor() {
    this.$modal = document.querySelector('.js-brand-modal');
    this.$buttons = document.querySelectorAll('[data-modal]');
    this.$close = this.$modal.querySelector('.js-modal-close');
    this.$items = this.$modal.querySelectorAll('[data-target]');
    this.events();
  }

  events() {
    this.$buttons.forEach((el) => {
      el.addEventListener('click', (e) => {
        const target = e.currentTarget.getAttribute('data-modal');
        const dir = e.currentTarget.getAttribute('data-dir');
        this.hideItems();
        this.$modal.querySelector(`[data-target="${target}"]`).classList.add('state_active');
        this.$modal.classList.add('state_active');
        console.log('SWIPER_dir', dir);
        window.global.indexSwiper[target].reInit(dir === null?'horizontal':dir);
      })
    });

    this.$close.addEventListener('click', () => {
      this.$modal.classList.remove('state_active');
    })
  }

  hideItems() {
    this.$items.forEach((el) => {
      el.classList.remove('state_active');
    });
  }
}

export default BrandModal;
