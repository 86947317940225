class HeaderLinks {
  constructor() {
    this.links = document.querySelector('.js-header-links');
    this.controls = document.querySelector('.js-side-menu');
    this.events();
  }

  events() {
    document.addEventListener('scroll', () => {
      if (window.innerWidth < 768 && window.scrollY > 0) {
        if (!this.links.classList.contains('state-on-scroll')) {
          this.links.classList.add('state-on-scroll');
          this.controls.classList.add('state-on-scroll');
          console.log('SCROLL_Y', window.scrollY);
        }
      } else {
        if (this.links.classList.contains('state-on-scroll')) {
          this.links.classList.remove('state-on-scroll');
          this.controls.classList.remove('state-on-scroll');
        }
      }
    });
  }
}

export default HeaderLinks;
