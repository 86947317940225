import Swiper from 'swiper';

class IndexSwiper {
  constructor(elem) {
    this.$elem = elem;
    this.swiper = null;
    this.id = elem.getAttribute('data-index-swiper');
    this.init('horizontal');
  }

  init(dir) {
    this.swiper = new Swiper(`[data-index-swiper="${this.id}"].js-index-swiper`, {
      slidesPerView: 'auto',
      centeredSlides: true,
      direction: dir,
      pagination: {
        el: `[data-index-swiper="${this.id}"] .index-pagination`,
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="index-swiper__bullet ' + className + '"></div>';
        },
      },
    });
  }

  reInit(dir) {
    this.swiper.destroy(true, true);
    this.init(dir);
  }
}

export default IndexSwiper;
